import React, { useState, useEffect } from 'react';
import './Sidebar.css';

const Sidebar = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    const sections = ["home", "projects", "skills", "contact"];
    let currentSection = "home";
    
    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element && window.scrollY + 50 >= element.offsetTop) {
        currentSection = section;
      }
    });
    
    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <button 
        className="mobile-menu-button"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        aria-label="Toggle menu"
      >
        ☰
      </button>

      <div 
        className={`overlay ${isMobileMenuOpen ? 'active' : ''}`}
        onClick={() => setIsMobileMenuOpen(false)}
      />

      <div className={`sidebar ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className="logo-container">
          <h1 className="logo">
            Rahul
            <br />
            Singh
          </h1>
        </div>

        <nav className="nav-menu">
          <ul>
            {["home", "projects", "skills", "contact"].map((section) => (
              <li key={section}>
                <a
                  href={`#${section}`}
                  className={activeSection === section ? 'active' : ''}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;