// src/Components/Skills.js

import React from "react";
import "./Skills.css";

const Skills = () => {
  return (
    <section id="skills" className="skills">
      <h1>Skills</h1>
      <ul>
        <li>ReactJS</li>
        <li>JavaScript</li>
        <li>HTML & CSS</li>
        <li>TailwindCSS</li>
        <li>Git & GitHub</li>
      </ul>
    </section>
  );
};

export default Skills;
