// src/Components/Hero.js

import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section id="home" className="hero">
      <h1>
        Hi, I’m <span>Rahul Singh Chauhan</span>
      </h1>
      <p>
        A passionate Web Developer specializing in ReactJS. I create modern,
        responsive, and user-friendly web applications.
      </p>
    </section>
  );
};

export default Hero;
