// src/App.js

import React from "react";
import "./App.css";
import Sidebar from "./Components/Sidebar";
import Hero from "./Components/Hero";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

const App = () => {
  return (
    <div className="App">
      <Sidebar />
      <div className="main-content">
        <Hero />
        <Projects />
        <Skills />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
