// src/Components/Contact.js

import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h1>Contact</h1>
      <p>Feel free to reach out to me via:</p>
      <a href="mailto:rahulsinghchauhanrj@gmail.com">Email: rahulsinghchauhanrj@gmail.com</a>
      <a href="https://github.com/rahulsingchauhan" target="_blank" rel="noopener noreferrer">
        GitHub: github.com/rahulsingchauhan
      </a>
    </section>
  );
};

export default Contact;
