// src/Components/Projects.js

import React from "react";
import "./Projects.css";

const Projects = () => {
  return (
    <section id="projects" className="projects">
      <h1>Projects</h1>
      <p>Here are some of the projects I’ve worked on:</p>
      <div className="project-grid">
        <div className="project-card">
          <h3>Portfolio Website</h3>
          <p>A modern personal portfolio built with ReactJS and TailwindCSS.</p>
        </div>
        <div className="project-card">
          <h3>Weather App</h3>
          <p>A weather forecasting app using OpenWeatherMap API and ReactJS.</p>
        </div>
        <div className="project-card">
          <h3>E-Commerce Platform</h3>
          <p>Fully functional e-commerce website with Firebase integration.</p>
        </div>
      </div>
    </section>
  );
};

export default Projects;
