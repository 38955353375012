// src/Components/Footer.js

import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      &copy; 2025 Rahul Singh Chauhan. All rights reserved.
    </footer>
  );
};

export default Footer;
